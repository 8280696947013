import React, { SetStateAction, useState } from 'react';
import Button from './bootstrap/Button';
import { ArrowBackIos, Delete, Save } from '@mui/icons-material';
import Input from './bootstrap/forms/Input';
import { Form } from '../models/forms/forms.module';
import {
	removeBackground,
	uploadFormBGImage
} from '../models/forms/forms.repository';
import { IPages } from '../frontend/src/Interfaces/IPages';
import { removePageBackground, uploadPagesBGImage } from '../models/pages/pages.repository';
import { useTranslation } from 'react-i18next';
import toast from "react-hot-toast";
import ImageGallery from "./ImageGallery/ImageGallery";
import { API_BASE_PATH } from "../API/API-routes";

interface IImageFormProps {
	setFormEditMode: React.Dispatch<
		SetStateAction<
			| ''
			| 'image'
			| 'style'
			| 'page-field'
			| 'page-style'
			| 'page-image'
			| 'page'
			| 'template'
		>
	>;
	currentForm: Form | IPages;
	formEditMode:
	| ''
	| 'image'
	| 'style'
	| 'page-field'
	| 'page-style'
	| 'page-image'
	| 'page'
	| 'template';
	formObject: 'Form' | 'Page' | 'Logo';
	getAllForms: () => void;
	companyId: string | undefined
	setCurrentForm: React.Dispatch<SetStateAction<Form | null>>
}

export const ImageForm = (props: IImageFormProps) => {
	const [file, setFile] = useState<File | null>(null);
	const { t } = useTranslation(['translation', 'menu']);

	const handleImageUpload = async () => {
		if (!file || !props.currentForm?.uuid) return;

		let data = new FormData();
		data.append('image', file);
		// Loggen, ob die Datei in `FormData` enthalten ist
		console.log('📂 Datei vor Upload:', {
			name: file.name,
			type: file.type,
			size: file.size, // Prüfen, ob die Größe korrekt ist
		});
		// Prüfen, was `FormData` tatsächlich enthält
		for (let pair of data.entries()) {
			console.log(`📦 FormData Inhalt: ${pair[0]} →`, pair[1]);
		}
		try {
			let response;

			if (
				'company_id' in props.currentForm &&
				props.formObject === 'Form' &&
				props.currentForm.company_id &&
				props.currentForm.id
			) {
				toast.promise(
					uploadFormBGImage(
						props.currentForm.company_id,
						props.currentForm.uuid,
						data,
					)
						.then((res: Form | null) => {
							if (res) {
								props.setCurrentForm(res)
								props.getAllForms()
							}
						})
						.catch((error) => error),
					{
						loading: 'Loading',
						success: 'Successfully uploaded backgroundimage ',
						error: 'Error something went wrong!',
					},
				);
			} else {
				if (props.companyId) {
					toast.promise(
						uploadPagesBGImage(props.currentForm.uuid, props.companyId, data)
							.then((res: Form | null) => {
								if (res) {
									props.setCurrentForm(res)
									props.getAllForms()
								}
							})
							.catch((error) => error),
						{
							loading: 'Loading',
							success: 'Successfully uploaded backgroundimage ',
							error: 'Error something went wrong!',
						},
					);
				}
			}
		} catch (error) {
			console.error('Upload failed:', error);
		}
	};

	const handleBGRemove = async () => {
		if (props.currentForm.uuid) {
			if (props.formObject === 'Form') {
				await toast.promise(
					removeBackground(props.currentForm.uuid)
						.then(res => {
							if (res) {
								props.setCurrentForm(res)
								props.getAllForms()
							}
						})
						.catch((error) => error),
					{
						loading: 'Loading',
						success: 'Successfully removed background',
						error: 'Error something went wrong removing your Background!',
					}
				)
			} else {
				await toast.promise(
					removePageBackground(props.currentForm.uuid)
						.then(res => {
							if (res) {
								props.setCurrentForm(res)
								props.getAllForms()
							}
						})
						.catch((error) => error),
					{
						loading: 'Loading',
						success: 'Successfully removed background',
						error: 'Error something went wrong removing your Background!',
					}
				)
			}
		}
	}

	return (
		<>
			<Button
				title={t('back')}
				color='dark'
				onClick={() => {
					props.setFormEditMode(props.formEditMode === 'page-image' ? 'page' : '');
					setFile(null);
				}}>
				<ArrowBackIos />
				{t('back')}
			</Button>
			{
				props.currentForm && props.currentForm.uuid && props.currentForm.bg_image && props.companyId && (
					<div style={{
						display: "flex",
						justifyContent: "center"
					}}>
						<img src={API_BASE_PATH + props.currentForm.bg_image} style={{ height: '150px' }} />
					</div>
				)
			}
			<ImageGallery
				getAllForms={props.getAllForms}
				formObject={props.formObject}
				setCurrentForm={props.setCurrentForm}
				currentBG={props.currentForm.bg_image}
				currentForm={props.currentForm}
			/>
			<label htmlFor='file-upload'>
				{t('chooseFile')}
				<Input
					id='file-upload'
					type='file'
					name='Image'
					onChange={(e: any) => {
						setFile(e.target.files[0]);
					}}
				/>
			</label>
			<Button
				color='dark'
				onClick={() => {
					handleImageUpload();
				}}>
				<Save />
				{t('upload')}
			</Button>
			{
				props.currentForm.uuid && (
					<Button
						style={{ flex: 1 }}
						color='warning'
						isDisable={props.currentForm.bg_image === null}
						onClick={() => {
							handleBGRemove()
						}}>
						<Delete />
					</Button>
				)
			}
		</>
	);
};

export default ImageForm;
