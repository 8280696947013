import { IFields } from '../Interfaces/IGeneral';
import { IPages } from '../Interfaces/IPages';

export const GlobalStyleAttributes = [
	'primaryColor',
	'backgroundColor',
	'buttonBackground',
	'buttonColor',
	'formTextColorHeadline',
	'formTextColorContent',
	'formInputColor',
];

export const initialStyles = [{
		name: '--primaryColor',
		value: 'rgba(0, 255, 255,1)'
	},
	{
		name: '--secondColor',
		value: 'rgba(255,255,255,1)'
	},
	{
		name: '--backgroundColor',
		value: 'rgba(0,0,0,1)'
	},
	{
		name: '--buttonColor',
		value: 'rgba(0,0,0,1)'
	},
	{
		name: '--buttonBackground',
		value: 'rgba(0, 255, 255,1)'
	},
	{
		name: '--formTextColorHeadline',
		value: 'rgba(0, 255, 255,1)'
	},
	{
		name: '--formTextColorContent',
		value: 'rgba(255,255,255,1)'
	},
	{
		name: '--formInputColor',
		value: 'rgba(255,255,255,1)'
	},
	{
		name: '--formInputColorComp',
		value: 'rgba(255,255,255,1)'
	}];


export const initialFields: Array<IFields> = [
	{
		name: 'Switch',
		dataType: 'Switch',
		frontend: 111,
		valuesToBeSelected: '',
		pageName: 'Seite 1',
		required: false,
		field_settings: null,
		order: -1,
		dep_info: null,
		dep_id: null,
	},
	{
		name: 'MultiSelect',
		dataType: 'MultiSelect',
		frontend: 111,
		valuesToBeSelected: '["Option1","Option2"]',
		pageName: 'Seite 1',
		required: false,
		field_settings: null,
		order: -1,
		dep_info: null,
		dep_id: null,
	},
	{
		name: 'MultiSelectBox',
		dataType: 'MultiSelectBox',
		frontend: 111,
		valuesToBeSelected: '["Option1","Option2"]',
		pageName: 'Seite 1',
		required: false,
		field_settings: null,
		order: -1,
		dep_info: null,
		dep_id: null,
	},
	{
		name: 'SingleSelect',
		dataType: 'SingleSelect',
		frontend: 111,
		valuesToBeSelected: '["Option1","Option2"]',
		pageName: 'Seite 1',
		required: false,
		field_settings: null,
		order: -1,
		dep_info: null,
		dep_id: null,
	},
	{
		name: 'SingleSelectBox',
		dataType: 'SingleSelectBox',
		frontend: 111,
		valuesToBeSelected: '["Option1","Option2"]',
		pageName: 'Seite 1',
		required: false,
		field_settings: null,
		order: -1,
		dep_info: null,
		dep_id: null,
	},
	{
		name: 'Textfield',
		dataType: 'TextField',
		frontend: 111,
		valuesToBeSelected: '',
		pageName: 'Seite3',
		required: false,
		field_settings: 'standard',
		order: -1,
		dep_info: null,
		dep_id: null,
	},
	{
		name: 'TextArea',
		dataType: 'TextArea',
		frontend: 111,
		valuesToBeSelected: '',
		pageName: 'Seite3',
		required: false,
		field_settings: 'standard',
		order: -1,
		dep_info: null,
		dep_id: null,
	},
	{
		name: 'Slider',
		dataType: 'Slider',
		frontend: 111,
		valuesToBeSelected: '{"min":"0","max":"100","steps":"1"}',
		pageName: 'Seite3',
		required: false,
		field_settings: null,
		order: -1,
		dep_info: null,
		dep_id: null,
	},
	{
		name: 'Range',
		dataType: 'Range',
		frontend: 111,
		valuesToBeSelected: '{"min":"0","max":"100","dist":"5","steps":"5"}',
		pageName: 'Seite3',
		required: false,
		field_settings: null,
		order: -1,
		dep_info: null,
		dep_id: null,
	},
	{
		name: 'Checkbox',
		dataType: 'Checkbox',
		frontend: 111,
		valuesToBeSelected: '',
		pageName: 'Seite3',
		required: false,
		field_settings: null,
		order: -1,
		dep_info: null,
		dep_id: null,
	},
	{
		name: 'File',
		dataType: 'File',
		frontend: 111,
		valuesToBeSelected: '{"fieldType" : ""}',
		pageName: 'Seite3',
		required: false,
		field_settings: null,
		order: -1,
		dep_info: null,
		dep_id: null,
	},
	{
		name: 'Appointment',
		dataType: 'Appointment',
		frontend: 111,
		valuesToBeSelected: '{"schedule" : {}, "steps": 1, "numberOfAppointments" : 1}',
		pageName: 'Seite3',
		required: false,
		field_settings: null,
		order: -1,
		dep_info: null,
		dep_id: null,
	},
	{
		name: 'Seperator',
		dataType: 'Seperator',
		frontend: 111,
		valuesToBeSelected: '{"style" : ""}',
		pageName: 'Seite3',
		required: false,
		field_settings: null,
		order: -1,
		dep_info: null,
		dep_id: null,
	},
];