import React, { SetStateAction, useEffect, useState } from 'react';
import { IFields } from "../../../Interfaces/IGeneral";
import { IDataCreate } from "../../../../../models/data/data.module";
import { FormControl, InputLabel, TextareaAutosize, TextField } from "@mui/material";
import { Textarea } from '../../../../../components/icon/bootstrap';

interface ITextAreaFormProps {
    field: IFields,
    fieldIndex: number,
    data?: Array<IDataCreate>,
    setData?: React.Dispatch<SetStateAction<Array<IDataCreate[]> | null>>;
    pageNumber: number
}

const ETextAreaForm = (props: ITextAreaFormProps) => {

    const [text, setText] = useState<string>("")

    useEffect(() => {
        if (props.data && props.data[props.fieldIndex] && props.data[props.fieldIndex].value !== undefined) {
            setText(props.data[props.fieldIndex].value)
        }
    }, [])

    const textChangeHandler = (e: any) => {
        if (props.data && props.setData) {
            let changedValues = [...props.data]
            changedValues[props.fieldIndex].value = e.target.value
            setText(e.target.value)
            props.setData((prevState: any) => {
                let newState = [...prevState];
                newState[props.pageNumber] = changedValues;
                return newState;
            });
        }
    }

    const InputLabelPropsStyles: React.CSSProperties = {
        color: getComputedStyle(document.documentElement).getPropertyValue('--formInputColor'),
        fontSize: '18px',
        padding: '12px 0 8px 0',
        zIndex: 1,
    };

    const formInputStyles: React.CSSProperties = {
        color: getComputedStyle(document.documentElement).getPropertyValue('--formInputColor'),
        textAlign: 'left',
        padding: '12px 0 8px 0',
        fontSize: '18px',
    };

    return (

        <FormControl sx={{ width: '100%', position: 'relative', marginTop: 2 }}>
            <InputLabel
                shrink
                sx={{
                    position: 'absolute',
                    top: '-10px',
                    // left: '12px',
                    background: 'white',
                    px: 1,
                    fontSize: '18px',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    color: getComputedStyle(document.documentElement).getPropertyValue('--formInputColor'),
                }}
            >
                {props.field.name}
            </InputLabel>
            <TextareaAutosize
                minRows={3}
                onChange={(e) => textChangeHandler(e)}
                value={text}
                style={{
                    width: '100%',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: '12px',
                    fontSize: '18px',
                    background: 'transparent',
                    outline: 'none',
                    resize: 'vertical',
                }}
                name={props.field.name.toLowerCase()}
                required={props.field.required}
            />
        </FormControl>

    )

};

export default ETextAreaForm;