import React, { SetStateAction, useEffect, useState } from 'react';
import { IFields } from "../../../Interfaces/IGeneral";
import { IDataCreate } from "../../../../../models/data/data.module";
import { TextField } from "@mui/material";

interface ITextFieldFormProps {
    field: IFields,
    fieldIndex: number,
    data?: Array<IDataCreate>,
    setData?: React.Dispatch<SetStateAction<Array<IDataCreate[]> | null>>;
    pageNumber: number;
    validateEmail: (e: any) => void;
}

const EEmailFormField = (props: ITextFieldFormProps) => {

    const [email, setEmail] = useState<string>("")

    useEffect(() => {
        if (props.data && props.data[props.fieldIndex] && props.data[props.fieldIndex].value !== undefined) {
            setEmail(props.data[props.fieldIndex].value)
        }
    }, [])

    const textChangeHandler = (e: any) => {
        if (props.data && props.setData) {
            let changedValues = [...props.data]
            changedValues[props.fieldIndex].value = e.target.value
            setEmail(e.target.value)
            props.setData((prevState: any) => {
                let newState = [...prevState];
                newState[props.pageNumber] = changedValues;
                return newState;
            });
        }
    }

    const InputLabelPropsStyles: React.CSSProperties = {
        color: getComputedStyle(document.documentElement).getPropertyValue('--formInputColor'),
        fontSize: '18px',
        padding: '12px 0 8px 0',
        zIndex: 1,
    };

    const formInputStyles: any = {
        color: getComputedStyle(document.documentElement).getPropertyValue('--formInputColor'),
        textAlign: 'left',
        padding: '12px 0 8px 0',
        fontSize: '18px',
        WebkitBoxShadow: '0 0 0 100px var(--backgroundColor) inset !important',
        WebkitTextFillColor: 'var(--formInputColor)',
    };


    return props.field.name.length > 25 ? (
        <>
            <div className="MPage__drop-target__field-label">
                {props.field.name}{props.field.required ? "*" : ""}
            </div>
            <TextField
                type={'email'}
                onChange={(e) => {
                    textChangeHandler(e)
                    props.validateEmail(e);
                }}
                sx={{ input: formInputStyles }}
                variant={'standard'}
                value={email}
                InputLabelProps={{
                    style: { ...InputLabelPropsStyles, padding: '8px 0' },
                }}
                InputProps={{
                    name: props.field.name.toLowerCase()
                }}
                required={props.field.required}
                name={props.field.name}
            />
        </>
    ) : (
        <TextField
            type={'email'}
            label={props.field.name}
            onChange={(e) => {
                textChangeHandler(e)
                props.validateEmail(e);
            }}
            variant={'standard'}
            value={email}
            InputLabelProps={{
                style: { ...InputLabelPropsStyles, padding: '8px 0' },
            }}
            InputProps={{
                name: props.field.name.toLowerCase(),
                autoComplete: props.field.name.toLowerCase(),
            }}
            sx={{ input: formInputStyles }}
            required={props.field.required}
            name={props.field.name}
        />
    );
};

export default EEmailFormField;